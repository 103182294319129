import React from "react"
import Layout from "../components/layout"
import '../assets/main.css'
import { MDBContainer, MDBBtn } from "mdbreact"

function MemberOnly() {


    return (
        <Layout>
            <MDBContainer fluid>

                <div className="container plain-conatiner">

                    <h1>MemberOnly coming soon....</h1>

                    

                </div>

            </MDBContainer>
        </Layout>
    );
}

export default MemberOnly